::-webkit-scrollbar { 
    display: none; 
}
.element { overflow: -moz-scrollbars-none; }
.element { -ms-overflow-style: none; }
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  position: relative
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active {
  background: #dedede !important;
  font-style: italic;
  font-weight: 600;
  
}
:root {
  --primary: rgb(49, 85, 220);
  --grey-text: green 
}

.app_app__mHNbJ {
  /* min-width: 1024px; */
  padding-top: 70px;
}

@media (max-width: 460px) {
  .app_app__mHNbJ {
    
    padding-top: 70px;
  }
}

.app_fixScroll__3V8vQ {
  overflow: hidden
}

.header_header__OvuDZ {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.06), 0 4px 5px 0 rgba(0,0,0,.06), 0 1px 9px 3px rgba(0,0,0,.08);
  background: #fff;
  border-color: rgba(0,0,0,.14);
  color: rgba(0,0,0,.54);
  fill: rgba(0,0,0,.54);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  -webkit-transition: box-shadow .2s ease-in-out;
  transition: box-shadow .2s ease-in-out;
  height: 70px;
  z-index: 1;
  position: fixed;
  width: 100%;
}

.header_headerCorner__3XcYi {
  width: 60px;
  text-align: center;
}

.header_headerCorner__3XcYi button {
  display: none;
}

.header_headerLockup__kudVr {
  position: relative;
  top: 15px;
  z-index: 1;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  display: flex;
  align-items: center;

  
}

.header_lockUpLogo__lQhq3 {
  height: 24px;
}


.header_headerNav__8H1q8 {
  position: relative;
  flex: 1 1;
  text-align: right;
  display: flex;
  height: 100%;
  justify-content: flex-end;
}

.header_headerNav__8H1q8>ul {
  display: inline-flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 0px;
}

.header_headerNav__8H1q8 li {
  font-size: 14px;
  margin-left: 32px;
  text-transform: uppercase;
  color:#FF527B;
  cursor: pointer;
}

.header_title__4BYXv {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
  margin-left: 20px;
}

.header_link__1b_7n {
  display: flex;
  align-items: center;
  text-decoration: none;
}

@media (max-width: 460px) {
  .header_headerCorner__3XcYi {
    margin-top:12px
  }

  .header_headerCorner__3XcYi button {
    display: block;
    border:none;
    background:none;
  }

  .header_headerLockup__kudVr {
    margin-left: 30px;
  }

} 
.sidebar_sidebar__39ofC {
  width: 240px;
  height: 100%;
  position: fixed;
  background: #fff;
  overflow-y: scroll;
}


.sidebar_grey-item__1u0vf {
  background: white !important;
  border-bottom: 1px solid #eaeaea;
  color: #757575 !important;
}

.sidebar_navigationListParent__1buQH {
  height: 100%;
}



.sidebar_navList__1fVgl {
  padding-top: 80px;
  margin: -2px 0 0;
  padding: 0;
  position: relative;
}

.sidebar_navList__1fVgl li a {
  background: #fff;
  color: var(--primary);
  display: block;
  cursor: pointer;
  text-decoration: none;
  padding: 15px 0 15px 25px;
  font-size: 16px;
  outline: none;
  /* -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease; */
}

.sidebar_navList__1fVgl li a:hover {
  color: #fff;
  background: var(--primary) !important;
}

.sidebar_active__G4rnZ {
  background: red
}

@media (max-width: 460px) {
  .sidebar_sidebar__39ofC {
    width: 240px;
    height: 100%;
    left: -240px;
    background: #fff;
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.06), 0 4px 5px 0 rgba(0,0,0,.06), 0 1px 9px 3px rgba(0,0,0,.08);
  }

  .sidebar_reveal__1_Lwj {
    -webkit-transform: translateX(240px);
            transform: translateX(240px)
  }
} 
.layout_content__R5T57 {
  margin-left: 240px;
  padding-left: 20px;
  padding-right: 60px;
  min-height: 100vh;
  display: flex;
}

@media (max-width: 460px) {
  .layout_content__R5T57 {
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
    /* min-height: 100vh; */
    display: flex;
  }
} 
.loader_loader__oqXwA {
  /* padding-left: 20px;
  padding-right: 60px; */
}

.loader_bar__1hen3 {
  background: #eaeaea;
  width: 100%
}
