:root {
  --primary: rgb(49, 85, 220);
  --grey-text: green 
}

.app {
  /* min-width: 1024px; */
  padding-top: 70px;
}

@media (max-width: 460px) {
  .app {
    
    padding-top: 70px;
  }
}

.fixScroll {
  overflow: hidden
}