.content {
  margin-left: 240px;
  padding-left: 20px;
  padding-right: 60px;
  min-height: 100vh;
  display: flex;
}

@media (max-width: 460px) {
  .content {
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
    /* min-height: 100vh; */
    display: flex;
  }
} 